@import url("https://use.typekit.net/otu3eor.css");

// 2. Include any default variable overrides here
@import "./maps";
@import "./variables";

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";

// 4. Include any default map overrides here

$colors: map-merge($colors, $club-colors);
$theme-colors: map-merge($theme-colors, $club-theme);

@at-root .goblins-theme {
  @each $color, $value in $club-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  @each $color, $value in $club-theme {
    --#{$variable-prefix}#{$color}: #{$value};
  }
}
