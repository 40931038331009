.list_item {
  border-top: $border-width solid $black;
  padding-top: calc($spacer * 0.5) !important;
  padding-bottom: calc($spacer * 0.5) !important;
  a {
    text-decoration: none;
  }
}

.user_tags {
  @extend .list-unstyled;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0 0.3em;
  margin-bottom: 0.5rem;
}

.user_tags-item {
  font-size: $font-size-sm;
  &:not(:last-child):after {
    content: ", ";
    display: inline;
  }
}
