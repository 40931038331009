$assets-path: "/assets/goblins";
$font-family-base: "bd-supper", sans-serif;
$font-family-secondary: "cubano", sans-serif;
$text-secondary-color: #878787;
$font-weight-normal: 400; //changed
$nav-link-font-size: 1.2rem; //changed
$nav-link-font-weight: 700; //changed
$border-width: 5px; //changed
$border-color: $black; //changed
$box-shadow: 4px 4px 0 rgba($black, 1); //changed
$btn-active-box-shadow: $box-shadow;
$btn-font-family: $font-family-secondary;
$nav-link-color: $dark; //changed
$nav-link-hover-color: $light; //changed
$nav-tabs-link-active-border-color: $black; //changed
$badge-color: $black;
$modal-content-border-color: rgba($black, 1); //changed
$modal-content-border-width: 2px; //changed
$modal-content-box-shadow-xs: 4px 4px 0 $black; //changed
$modal-content-box-shadow-sm-up: 4px 4px 0 $black; //changed
$btn-close-bg-tags: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$white}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>"); //changed-added
$offcanvas-horizontal-width: 280px; //changed
$offcanvas-border-color: $primary; //changed
$offcanvas-border-width: 4px; //changed
$card-border-color: rgba($black, 1); //changed
$card-cap-bg: rgba($white, 1); //changed
$headings-font-family: $font-family-secondary;
