.btn {
  box-shadow: $box-shadow;
  margin-bottom: 4px;
  &:hover,
  & > .btn {
    box-shadow: none;
  }
  &.btn-sm {
    font-size: 0.875rem;
  }

  &.btn-primary {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &.btn-secondary {
    background-color: $off-white;
    border-color: $off-white;

    &:hover {
      background-color: $off-white;
      border-color: $off-white;
    }
  }
}
