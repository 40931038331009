.site-roles {
  &_input-search {
    height: 40px;
    font-size: 16px;
    width: 100%;
    padding: 0px 20px 0px 20px;
    border-radius: 10px;
    outline: none;
    border: solid black 2px;

    &::-webkit-search-cancel-button {
      margin-right: 25px;
    }
  }

  &_invisible {
    display: none;
  }

  &_icon-search {
    position: absolute;
    right: 13px;
    top: 8px;
    width: 25px;
  }
}
