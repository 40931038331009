@mixin avatar-container-mixin {
  // padding-left: 0;
  // padding-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

@mixin avatar-mixin {
  //width: 100%;
  //height: auto;
}

.avatar-container-small {
  @include avatar-container-mixin;
  width: 72px;
  height: 72px;
  .avatar {
    @include avatar-mixin;
    max-width: 72px;
  }
}

.avatar-container-map {
  @include avatar-container-mixin;
  width: 100px;
  height: 100px;
  .avatar {
    @include avatar-mixin;
    width: 100px;
  }
}

.avatar-container {
  @include avatar-container-mixin;
  min-width: 180px;
  min-height: 180px;
  max-width: 180px;
  max-height: 180px;
  .avatar {
    @include avatar-mixin;
    width: 180px;
  }
}

.avatar-container-large {
  @include avatar-container-mixin;
  min-width: 350px;
  min-height: 350px;
  max-width: 350px;
  max-height: 350px;
  @media (max-width: 450px) {
    min-width: auto;
  }
  .avatar {
    @include avatar-mixin;
    width: 350px;
  }
}

.avatar-container-fluid {
  width: 100%;
  & .avatar {
    width: 100%;
    height: auto;
  }
}
