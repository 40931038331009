.offcanvas {
  width: 280px !important;
}
a.tt-link {
  img.position-absolute {
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
  }

  &:hover {
    img.position-absolute {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }
  }
}
@media (max-width: 500px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 992px) {
  body {
    padding-left: $offcanvas-horizontal-width;
    &.nomenu {
      padding-left: 0 !important;
    }
  }
  .navbar-main {
    display: none;
  }
  .offcanvas.offcanvas-start {
    visibility: visible !important;
    transform: none;
    .offcanvas-header .btn-close {
      visibility: hidden;
    }
  }
}

.mobile-app {
  .offcanvas {
    padding-top: 3rem;
  }
}
