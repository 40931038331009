.modal {
  // width: auto;
  // height: auto;
  // max-width: 90%;
  // padding-left: $spacer;
  // padding-right: $spacer;
  box-shadow: $modal-content-box-shadow-sm-up;
  display: block;
}

.modal-dialog {
  top: 30px;
  padding-bottom: 60px;
}

//
//#WEB3_CONNECT_MODAL_ID {
//  z-index: 2000;
//  position: relative;
//}
