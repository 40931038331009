.cursor-pointer {
  cursor: pointer;
}

.badge-wrapper {
  position: relative;
  font-family: "Press Start 2P", cursive;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../../assets/generic/roles/role-border-small.svg");
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
  }
  .badge-wrapper--data {
    display: flex;
    align-items: center;
    text-align: center;
    max-height: 63px;
    overflow: hidden;
    height: 100%;
    padding-left: 7px;
    margin-top: 1px;
  }

  .badge-extra-count {
    font-size: 11px;
    color: black;
    font-family: "cubano", sans-serif;
  }
}

.role-badge-small {
  margin-right: 0.2em;
  display: flex;
  align-items: center;

  img {
    max-height: 22px;
  }
  .badge-wrapper {
    padding: 1px;
    height: 25px;
    width: 25px;
    box-sizing: content-box;
    .badge-wrapper--data {
      display: flex;
      height: 100%;
      padding-left: 0;
      margin-top: 0;
      justify-content: center;
    }
  }
}

.role-badge {
  a {
    display: flex;
  }
  margin-right: 0.3em;
  margin-bottom: 0.3em;
  display: flex;

  img {
    max-height: 41px;
  }
  .badge-wrapper {
    min-height: 66px;
    box-sizing: content-box;
    padding: 2px 2px 0 2px;
    &::before {
      background-image: url("../../../assets/generic/roles/role-border-big.svg");
    }
    .badge-wrapper-text {
      display: -webkit-box;
      display: -moz-box;
      justify-content: center;
      align-items: center;
      line-height: 1.2rem;
      font-size: 14px;
      flex-grow: 1;
      padding-right: 9px;
      padding-left: 3px;
      font-family: "cubano", sans-serif;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      -moz-box-orient: vertical;
      -moz-line-clamp: 2;
      > * {
        margin-bottom: 0;
      }
    }
  }
}

.post-image-container {
  position: relative;

  .image-delete-icon {
    position: absolute;
    padding: 1px;

    top: 0px;
    left: 0px;
  }
}

.comments-section {
}

.file-input {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
  top: 50px;
  left: 0;
  display: none;
}

// Container must have max-width: 100% to limit width of contents.
.scaled-text-super-parent {
  max-width: 100%;
  overflow-x: hidden;
}

// Parent must have min-width: 0 to allow text to scale down.
.scaled-text-parent {
  min-width: 0;
}

// Scaled text itself is the element that will scale down.
.scaled-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#id_checkin_location_0,
#id_checkin_date,
#id_checkin_days {
  height: 50px;
}

.logo-landing {
  color: $badge-color;
}
