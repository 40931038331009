#intersect-trigger {
  line-height: 1px;
  height: 1px;
}

/*.chatpost-items::-webkit-scrollbar {
  height: 0px;
}*/

.chatpost-items {
  overflow-x: hidden !important;
}
