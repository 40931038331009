.accordion-item {
  h3 {
    transition: all 0.2s ease-in-out;
    font-weight: 600;
  }

  [aria-expanded="false"] {
    position: relative;
    transition: all 0.2s ease-in-out;
    &:before {
      content: "▸";
      position: absolute;
      left: -25px;
      top: 0px;
      transition: all 0.2s;
    }
  }
  [aria-expanded="true"] {
    position: relative;
    color: $primary;
    transition: all 0.2s ease-in-out;
    &:before {
      content: "▸";
      color: $primary;
      position: absolute;
      left: -23px;
      top: 0px;
      transform: rotate(90deg);
      transition: all 0.2s ease-in-out;
    }
  }
}
