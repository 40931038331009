.btn {
  //text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 30px;
    max-width: 40px;
    max-height: 30px;
  }
}

.social-icon {
  svg {
    max-width: 30px;
    max-height: 35px;
  }
  .btn-close-punks {
    // color: $custom-text-color-primary !important;
    border: none;
    background: none;
  }
}
#punks-map {
  height: 550px;
}

.btn-inverse {
  color: var(--#{$prefix}btn-bg);
  background: var(--#{$prefix}btn-color);
  border-color: var(--#{$prefix}btn-color);
}

.btn.selected {
  background: $gray-900;
  border-color: $gray-900;
  color: $white;
}

.modal-content {
  .btn-close {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 99;
  }
}
.rounded-pill {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  .image-picker {
    display: none;
  }
}

share-button {
  border-radius: 0;
}

.btn-unstyled {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
}

.btn.done {
  background-color: #23d577 !important;
  opacity: 1 !important;
  border-color: #23d577 !important;
  color: white !important;
}
