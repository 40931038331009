$white: #fff;
$gray-100: #f1f2f6;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #878787;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
// scss-docs-end gray-color-variables

// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
);

$dkblue: #000917;
$blue: #593d7e;
$ltblue: #def1ff;
$indigo: #160336;
$purple: #8d44f2;
$pink: #e07fa7;
$red: #dc3545;
$orange: #fd7e14;
$ltyellow: #b7f019;
$yellow: #f5a801;
$green: #417202;
$teal: #2093c9;
$cyan: #275e9e;
$mint: #49ab78;
$off-white: #fffbf5;

$club-colors: (
  "blue": $blue,
  "dkblue": $dkblue,
  "ltblue": $ltblue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
  "mint": $mint,
  "off-white": $off-white,
);

$primary: $mint;
$secondary: $white;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $black;
$highlight: $ltyellow;

$club-theme: () !default;
$club-theme: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $club-theme
);
