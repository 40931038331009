.nav-link {
  font-family: $font-family-secondary;
}

.navbar-main {
  background-color: $white;
}

#menu {
  .nav-link {
    position: relative;

    &:not(.nav-link-logout):after {
      content: " ";
      display: block;
      position: absolute;
      width: 85%;
      height: 2px;
      background-color: $black;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
