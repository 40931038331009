@import url("https://use.typekit.net/otu3eor.css");

html,
body {
  width: 100%;
  height: 100%;
}

body {
  &.body-bg {
    background-image: url("#{$assets-path}/background-logged-in.png");
  }
}
