.nav-link {
  &:hover,
  &:focus {
    background-color: $primary;
  }
}

.navbar-brand img {
  // max-width: 240px;
}

.hamburger .line {
  background-color: $primary;
}

#sidenavToggler {
  box-shadow: none;
}

.nav.nav-tabs {
  @media (max-width: 575px) {
    display: flex;
    .nav-item {
      width: 33.33%;
      flex-grow: 1;
      min-width: min-content;
      text-align: center;
      border: $light 1px solid;
      .nav-link {
        padding-left: 2px;
        padding-right: 2px;
      }
    }
  }
}

.nav-tabs .nav-link {
  background-color: $primary;
  color: $white;
  &:hover {
    opacity: 0.8;
    border: $border-width solid $primary;
  }
  &.active:hover {
    opacity: 1;
    // border: $border-width solid $black;
    // background-color: $white;
    cursor: default;
  }
}

.notifications {
  display: none;
}

.mobile-app,
.mobile-app-test {
  #navbarBlur {
    padding-top: 3rem;
  }

  #sidenavToggler {
    display: none;
  }

  .notifications {
    display: inline-block;
  }
}
