.project-flexbox {
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    .flex-column {
      align-items: center;
      text-align: center;
    }
  }
}
