#chat-context-menu {
  position: fixed;
  z-index: 10000;
  border-radius: 5px;
  transform: scale(0);
  transform-origin: top left;
}

#chat-context-menu.visible {
  transform: scale(1);
  transition: transform 200ms ease-in-out;
}

//#chat-context-menu
.chat-context-menu_item {
  cursor: pointer;
  padding: 8px 10px;
  border-radius: inherit;
}

//#chat-context-menu
.chat-context-menu_item:hover {
  backdrop-filter: brightness(120%);
}

.chat-post-item {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#chat-loading-overlay {
  z-index: 10000;
  position: fixed;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
}

#chat-loading-overlay.visible {
  display: flex;
}

.chat-post-tapped {
  background: rgba($primary, 0.3) !important;
}

.autocomplete-selected {
  background: $primary;
  color: $light;
}

.highlightable-post {
  position: relative;
}
.highlighted-post-after {
}
.highlighted-post-after-animate {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  opacity: 1;
  transition: opacity 3000ms;
  background: rgba($primary, 0.65) !important;
}
