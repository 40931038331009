@media (max-width: 400px) {
  .project-link {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
  }
}

@media (max-width: 1200px) {
  .project-logo {
    width: 180px !important;
    height: 180px !important;
  }
}

@media (max-width: 767.98px) {
  .project-logo {
    width: 120px !important;
    height: 120px !important;
  }
}

.page-header {
  min-height: 100px;
  height: 300px;
  position: relative;
  align-items: center;
  & > img {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    z-index: -1;
  }
  @media (max-width: 575px) {
    height: 200px;
  }
}

.project-link {
  width: 100% !important;
}

.project-logo {
  width: 260px;
  height: 260px;
  border: solid 1px $black;
  & > img {
    height: 80%;
  }
}

.project-attributes-container {
  line-height: 30px;
}

.project-list p span:before {
  /* This needs to specify a component, else it works globally. Change parent accordingly*/
  content: "...";
}

.less-text-label:not(.collapsed)::after {
  content: "less";
}
