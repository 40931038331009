.checkbox {
  padding-top: 5px;
  label {
    display: flex;
    gap: 5px;
    align-items: center;
    .form-control {
      width: auto;
      appearance: auto;
    }
  }
}

select.form-control:not([multiple]) {
  @extend .form-select;
}

label.required:after {
  content: " *";
  color: red;
}

div.form-group {
  margin-top: 7px;
}

.error-msg {
  color: red;
}
