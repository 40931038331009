/* Probably can be deleted */

.plane {
  content: "";
  position: relative;

  &:after {
    position: absolute;
    top: -25px;
    right: -20px;
    content: url("#{$assets-path}/svg/icon/plane_map.svg");
  }
}

.map-bubble {
  color: $black;
}
