.bg-primary {
  a {
    color: $white !important;
  }
}

.badge.bg-primary {
  color: $white;
}

.text-secondary {
  color: $text-secondary-color !important;
}
