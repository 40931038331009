.hamburger {
  .line {
    width: 32px;
    height: 3px;
    background-color: $primary;
    display: block;
    margin: 7px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    cursor: pointer;
  }
}

%active {
  .line:nth-child(2) {
    opacity: 0;
  }

  .line:nth-child(1) {
    transform: translateY(10px) rotate(45deg);
  }

  .line:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
  }
}

.hamburger.is-active,
.is-active > .hamburger {
  @extend %active;
}
